import { PublicLayoutComponent } from "./Layout/public-layout/public-layout.component";
import { RedirectVidaSaludGuard } from "./redirect-vida-salud.guard";
import { Routes } from "@angular/router";
import { SecureLayoutComponent } from "./Layout/secure-layout/secure-layout.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";

export const AppRoutes: Routes = [
  {
    path: "home",
    component: PublicLayoutComponent,
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "admin",
    component: SecureLayoutComponent,
    loadChildren: () =>
      import("./secure/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "auth",
    component: PublicLayoutComponent,
    loadChildren: () =>
      import("./public/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: PublicLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "oferta/:typeOffer/pagina-principal",
        loadChildren: () =>
          import("./modules/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "productos-vida",
        loadChildren: () =>
          import("./modules/home/home.module").then((m) => m.HomeModule),
        data: {
          title: "Productos Vida",
          path: "/productos-vida",
          isLanding: true,
        },
      },
      {
        path: "cuenta-segura",
        loadChildren: () =>
          import("./modules/cuenta/cuenta.module").then(
            (m) => m.CuentaModule
          )
      },
      {
        path: "tarjeta-segura",
        redirectTo: ""
      },
      {
        path: "travel",
        loadChildren: () =>
          import("./modules/travel/travel.module").then(
            (m) => m.TravelModule
          )
      },
      {
        path: "hospi-seguro",
        loadChildren: () =>
          import("./modules/hospi-seguro/hospi-seguro.module").then(
            (m) => m.HospiSeguroModule
          ),
      },
      {
        path: "salud-vida-assist",
        loadChildren: () =>
          import("./modules/vida-salud/vida-salud.module").then(
            (m) => m.VidaSaludModule
          ),
      },
      {
        path: "celular-protegido",
        canActivate: [RedirectVidaSaludGuard],
      },
      {
        path: "patitas-protegidas",
        canActivate: [RedirectVidaSaludGuard],
      },
      {
        path: "auto-contenido",
        loadChildren: () =>
          import("./modules/auto-contenido/auto-contenido.module").then(
            (m) => m.AutoContenidoModule
           ),
      },
      {
        path: "terms-conditions",
        loadChildren: () =>
          import(
            "./modules/terms-conditions/terms-conditions.module"
          ).then((m) => m.TermsConditionsModule)
      },
      {
        path: "privacy-policies",
        loadChildren: () =>
          import(
            "./modules/privacy-policies/privacy-policies.module"
          ).then((m) => m.PrivacyPoliciesModule),
      },
      {
        path: "agenda-una-llamada",
        loadChildren: () =>
          import("./components/schedule-call/schedule-call.module").then(
            (m) => m.ScheduleCallModule
          )
      },
      {
        path: "keyman-bienestar",
        loadChildren: () =>
          import("./modules/keyman/keyman.module").then(
            (m) => m.KeymanModule
          )
      }
    ],
  },
  {
    path: "**",
    component: PublicLayoutComponent,
    children: [
      {
        path: "",
        component: NotFoundComponent
      }
    ]
  },
];
